import React from "react"
import SEO from 'components/SEO/seo'
import ElementarySchool58 from "components/Textbooks/ES58";
import MainTemplate from 'templates/MainTemplate'


const SPPs = () => (
  <MainTemplate>
      <SEO title="Podręczniki dla szkół podstawowych klasy 5-8" /> 
  <ElementarySchool58/>
  
  </MainTemplate>
)

export default SPPs
